<template>
    <div>
        <!-- <hr v-if="depth !=0" style="margin: 3px 0">     -->
        <div class="node-hover border-t">
            <div
                :style="{'margin-left':30 * depth + 'px'}"
            >
                <div class="d-flex justify-content-between">
                    <div  class="node">
                        <span v-if="node?.type == 'group'">{{ node?.name }}</span>
                    </div>
                </div>
                <div
                    class="d-flex cursor-initial justify-content-between"
                    v-if="node?.type == 'endpoint'"
                >
                    <span class="mb-0">{{ node?.name }}</span>
                    <span class="mb-0" role="button" @click="goToGeneralLedger(node.id)">{{ commaFormat(node.total) }}</span>
                </div>
            </div>
        </div>
        <TreeBrowser
            v-for="item in node?.child"
            :key="item.name"
            :parent="node"
            :depth="depth + 1"
            :node="item"
        />
        <div v-if="node?.type == 'group'">
            <!-- <hr style="margin: 3px 0"> -->
            <div class="node-hover border-t border-b">
                <div class="d-flex justify-content-between">
                    <strong
                        :style="{'margin-left':30 * depth + 'px'}"
                    >
                        Total {{ node.name}}
                    </strong>
                    <strong>Tk. {{ commaFormat(node.total) }}</strong>
                </div>
                <!-- <hr style="margin: 3px 0"> -->
            </div>
        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
import handleRedirectToPage from "@/services/modules/redirectToPage";

export default {
    name: 'TreeBrowser',
    data () {
        return {
            expanded: true,
        }
    },
    computed: {
        hasChildren () {
            return this.node?.child?.length
        },
        type () {
            return this.node?.type
        }
    },
    props: {
        node: {
            default: {}
        },
        depth: {
            type: Number,
            default: 1
        },
        parent: {
            default: []
        }
    },
  setup() {
    const {commaFormat} = figureFormatter()
    const {goToPage} = handleRedirectToPage()

    const goToGeneralLedger = (headId) => {
      goToPage("general-ledger", headId)
    }

    return {
      commaFormat,
      goToPage,
      goToGeneralLedger
    }
  },
}
</script>

<style scoped>
span {
    font-size:1.2em;
}
</style>
