import {computed, inject} from 'vue'
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import useDate from "@/services/utils/day";

export default function handleRedirectToPage() {
    const store = useStore();
    const router = useRouter()
    const route = useRoute()
    const showError = inject("showError");
    const {firstDateOfMonth, lastDateOfMonth} = useDate()

    const sidebar = computed(() => store.getters["company/getSidebar"]);

    const goToPage = (routeName, headId = '', isQuery = true) => {
        const checkRoute = sidebar.value.flatMap(menu => menu.submenus)
            .flatMap(submenu => submenu.pages)
            .find(page => page.route == routeName);
        if (typeof checkRoute?.id != 'undefined') {
            goToPageLink(routeName, checkRoute.id, headId, isQuery)
        } else {
            showError(`${routeName} route not found`)
        }
    }

    const goToPageLink = (routeName, pageId, headId, isQuery) => {
        const {companyId, moduleId, menuId} = route.params;

        let query = {
            start: firstDateOfMonth,
            end: lastDateOfMonth,
            page: 1,
            ...(headId && {headId})
        }
        let params = {
            companyId: companyId,
            moduleId: moduleId,
            menuId: menuId,
            pageId: pageId,
        }

        let paramsData = {
            name: routeName,
            params: params
        };

        if (isQuery) {
            paramsData.query = query
        }

        const routeData = router.resolve(paramsData)

        window.open(routeData.href, '_blank');
    }

    return {
        goToPage,
        showError
    }
}
